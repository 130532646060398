import { gql, useLazyQuery } from '@apollo/client';
import { useLocation } from '@reach/router';
import { graphql, navigate } from 'gatsby';
import { parse } from 'query-string';
import * as React from 'react';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from '../components/common/breadcrumbs/Breadcrumbs';
import Layout from '../components/layout/Layout';
import SideBar from '../components/layout/SideBar';
import ProductSearchCard from '../components/search/ProductSearchCard';
import { ERROR_ENTITY, getPageLink, GQL_DO_SEARCH } from '../constants';
import './SearchResultsPage.scss';
import {
  SET_LOADING_OFF,
  SET_LOADING_ON,
  SET_SEARCH_DATA,
  SET_SEARCH_KEYWORD,
} from '../context/actions/ActionTypes';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '../context/GlobalContextProvider';
import { errorHandler, showUnexpectedError } from '../errorHandler';
import './AllProductsPage.scss';
import SearchBar from '../components/layout/SearchBar';
import { PageContentType } from '../context/PageContentType';

/**
 * Search Results Page
 *
 * @param props
 * @constructor
 */
const SearchResultsPage: React.FunctionComponent<PageContentType> = props => {
  const { t } = useTranslation();

  const dispatch = useContext(GlobalDispatchContext);
  const state = useContext(GlobalStateContext);

  const location = useLocation();
  const params = parse(location.search);
  const q = params?.q || '';

  const bc = [
    { label: 'home' },
    { label: 'chevron' },
    {
      label: t('breadcrumbs.searchResults'),
      path: getPageLink(props.pageContext.lang, 'SEARCH_RESULTS_PAGE'),
    },
  ];

  const [
    doSearch,
    {
      loading: doSearchDataLoading,
      data: doSearchData,
      error: doSearchDataError,
    },
  ] = useLazyQuery(
    gql`
      ${GQL_DO_SEARCH}
    `,
    {
      fetchPolicy: 'network-only',
    },
  );

  useEffect(() => {
    dispatch({ type: SET_SEARCH_KEYWORD, searchKeyword: q });
  }, []);

  useEffect(() => {
    dispatch({
      type: SET_LOADING_ON,
    });

    doSearch({
      variables: {
        search: state.searchKeyword,
      },
    });
  }, [state.searchKeyword]);

  useEffect(() => {
    if (doSearchDataLoading) {
      dispatch({
        type: SET_LOADING_ON,
      });
      return;
    } else {
      dispatch({
        type: SET_LOADING_OFF,
      });
    }

    if (doSearchDataError) {
      errorHandler(
        dispatch,
        t,
        doSearchDataError,
        ERROR_ENTITY.SEARCH_RESULTS,
        props.pageContext.lang,
      );

      return;
    }

    if (doSearchData) {
      if (doSearchData?.searchDataProducts) {
        dispatch({
          type: SET_SEARCH_DATA,
          searchData: doSearchData.searchDataProducts,
        });

        return;
      }

      // Handling unexpected error (should never happen)
      showUnexpectedError(dispatch);

      return;
    }
  }, [doSearchDataLoading, doSearchData, doSearchDataError]);

  return (
    <Layout
      lang={props.pageContext.lang}
      currentLangUrl={props.pageContext.currentLangUrl}
      otherLangUrl={props.pageContext.otherLangUrl}
      pageId={getPageLink(props.pageContext.lang, 'SEARCH_RESULTS_PAGE')}
      pageTitle={t('searchResultsPage.title')}
    >
      <div className="ontario-row">
        <div className="ontario-columns ontario-small-12 ontario-medium-9">
          <Breadcrumbs bc={bc} lang={props.pageContext.lang} />

          <SearchBar lang={props.pageContext.lang} />

          <h1>{t('searchResultsPage.title')}</h1>

          {state.searchData.map((v, k) => {
            return (
              <ProductSearchCard
                key={k}
                lang={props.pageContext.lang}
                productId={v.productId}
                name={v.name}
                description={v.description}
              />
            );
          })}

          {!state.searchData?.length ? (
            <>
              <p>{t('buttons.searchProductsNotFound') + ' "' + q + '"'}</p>

              <div className={'mdmp__goto-all-products'}>
                <button
                  onClick={() => {
                    navigate(
                      getPageLink(props.pageContext.lang, 'ALL_PRODUCTS_PAGE'),
                    );
                  }}
                  className="ontario-button ontario-button--primary"
                >
                  {t('buttons.goToAllProductsPage')}
                </button>
              </div>
            </>
          ) : (
            ''
          )}
        </div>

        <SideBar />
      </div>
    </Layout>
  );
};

export default SearchResultsPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
