import { navigate } from 'gatsby';
import React, { useContext, useEffect } from 'react';
import 'react-tabs/style/react-tabs.css';
import {
  ERROR_ENTITY,
  getPageLink,
  GQL_GET_PRODUCT_DATA,
  MODAL_WINDOW_TYPE,
} from '../../constants';
import productImage from '../../images/product-image.svg';
import './ProductSearchCard.scss';
import { useTranslation } from 'react-i18next';
import {
  SET_LOADING_OFF,
  SET_LOADING_ON,
  SET_MODAL_WINDOW_OPEN,
  SET_PRODUCT_DATA,
} from '../../context/actions/ActionTypes';
import { GlobalDispatchContext } from '../../context/GlobalContextProvider';
import { gql, useLazyQuery } from '@apollo/client';
import { errorHandler, showUnexpectedError } from '../../errorHandler';

type Props = {
  lang: string;
  productId: string;
  name: string;
  description: string;
};

/**
 * Product Search Card
 *
 * @param props
 * @constructor
 */
const ProductSearchCard: React.FunctionComponent<Props> = props => {
  const { t } = useTranslation();

  const dispatch = useContext(GlobalDispatchContext);

  const [
    getProductData,
    { loading: productDataLoading, data: productData, error: productDataError },
  ] = useLazyQuery(
    gql`
      ${GQL_GET_PRODUCT_DATA}
    `,
    {
      fetchPolicy: 'network-only',
    },
  );

  useEffect(() => {
    if (productDataLoading) {
      dispatch({
        type: SET_LOADING_ON,
      });
      return;
    } else {
      dispatch({
        type: SET_LOADING_OFF,
      });
    }

    if (productDataError) {
      errorHandler(
        dispatch,
        t,
        productDataError,
        ERROR_ENTITY.PRODUCT_DETAILS,
        props.lang,
      );

      return;
    }

    if (productData) {
      if (productData?.dataProductsDetails) {
        dispatch({
          type: SET_PRODUCT_DATA,
          productId: productData.dataProductsDetails[0].productId,
          productData: productData.dataProductsDetails[0],
        });

        showModalWindow();

        return;
      }

      // Handling unexpected error (should never happen)
      showUnexpectedError(dispatch);

      return;
    }
  }, [productDataLoading, productData, productDataError]);

  const showModalWindow = () => {
    dispatch({
      type: SET_MODAL_WINDOW_OPEN,
      modalWindow: {
        isOpen: true,
        type: MODAL_WINDOW_TYPE.SAMPLE_DATA,
        closeButtonTitle: 'Close',
        useHeader: true,
        customButtons: false,
        productDetailsURL:
          getPageLink(props.lang, 'PRODUCT', 'PRODUCT_DETAILS') +
          '/' +
          props.productId,
        productId: props.productId,
      },
    });
  };

  return (
    <div className={'mdmp__product-search-card'}>
      <div className={'mdmp__product-search-card-block'}>
        <div className={'mdmp__product-search-card-name-block'}>
          <img src={productImage} alt="" />
          <div className={'mdmp__product-search-card-name'}>{props.name}</div>
        </div>
        <div className={'mdmp__product-search-card-details'}>
          <button
            className={'ontario-button ontario-button--secondary'}
            onClick={() => {
              getProductData({
                variables: {
                  productIds: [props.productId],
                },
              });
            }}
          >
            {t('buttons.dataSample')}
          </button>

          <button
            className={'ontario-button ontario-button--primary'}
            onClick={() => {
              navigate(
                getPageLink(props.lang, 'PRODUCT', 'PRODUCT_DETAILS') +
                  '/' +
                  props.productId,
              );
            }}
          >
            {t('buttons.productDetails')}
          </button>
        </div>
      </div>

      <div className={'mdmp__product-search-card-description'}>
        {props.description}
      </div>
    </div>
  );
};

export default ProductSearchCard;
